import React from "react";

import { LoadingContainer } from "@interactive-investor/onestack-web-shared";

export interface RegisterPageProps {
  location: any;
}

export interface RegisterPageState {
  mounted: boolean;
}

class RegisterPage extends React.Component<
  RegisterPageProps,
  RegisterPageState
> {
  constructor(props: RegisterPageProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    // We want this to be a client-only component, so only render something if we are mounted
    if (this.state.mounted) {
      return (window.location.href = "https://www.ii.co.uk");
    }

    // If not mounted, render empty
    return <LoadingContainer title="Register for Research Account" />;
  }
}

export default RegisterPage;
